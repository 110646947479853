import { useEffect, useState } from "react";
import { usePanelistsStore } from "../../../../store/PanelistsInsight/usePanelistsStore";
import { Autocomplete, Box, Button, createFilterOptions, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputLabel, TextField } from "@mui/material";
import { useSubscribeStore } from "../../../../store/Subscribe/useSubscribeStore";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";

const filter = createFilterOptions();

export const SubscribeStatusPanelistModal = ({ seeModal, setSeeModal, panelist, pagination, columnFilters }) => {
    const modalType = seeModal.subscribe ? 'subscribe' : 'unsubscribe';
    const [form, setForm] = useState({
        motive: '',
        description: ''
    });
    const {
        fetchPanelists
    } = usePanelistsStore(state => state);
    const {
        isLoading: motiveIsLoading,
        isError: motiveIsError,
        fetchSubscribe,
        fetchUnsubscribe,
        fetchGetMotives,
        motives
    } = useSubscribeStore(state => state);
    const handleCloseDialog = () => {
        setSeeModal({ ...seeModal, [modalType]: !seeModal[modalType] })
    }
    const selectModalText = () => {
        const text = {
            title: "",
            contentText: "",
            textAreaPlaceholder: "",
        };
        if (modalType === 'subscribe') {
            text.title = "Habilitar cuenta";
            text.contentText = "habilitar la cuenta de " + panelist?.email;
            text.textAreaPlaceholder = "Explica el motivo por el que se habilita esta cuenta.";
        } else if (modalType === 'unsubscribe') {
            text.title = "Expulsar del panel";
            text.contentText = "expulsar del panel a " + panelist?.email;
            text.textAreaPlaceholder = "Explica el motivo de la expulsión.";
        }
        return text;
    }
    const handleChangePanelistStatus = async () => {
        const data = {
            token: panelist.token,
            origin: 'admin',
            motive: form.motive,
            description: form.description,
        };
        if (modalType === 'subscribe') {
            fetchSubscribe(data).then(({ status, data }) => {
                if (status === 200) {
                    Swal.fire({
                        title: 'Panelista activo',
                        text: data?.message ?? `La cuenta de ${panelist.email} fue reactivada.`,
                        icon: 'success'
                    });
                    handleCloseDialog();
                    fetchPanelists(pagination, columnFilters)
                }
            });
        } else if (modalType === 'unsubscribe') {
            fetchUnsubscribe(data).then(({ status, data }) => {
                if (status === 200) {
                    Swal.fire({
                        title: 'Panelista suspendido',
                        text: data?.message ?? `Se ha expulsado a ${panelist.email} del panel.`,
                        icon: 'success'
                    });
                    fetchPanelists(pagination, columnFilters)
                    handleCloseDialog();
                }
            });
        }
    }

    const transformOptionLabel = (option) => {
        // Si apreta enter
        if (typeof option === 'string') {
            return option;
        }
        // Si usa "añadir..."
        if (option.inputValue) {
            return option.inputValue;
        }
        // Si selecciona de la lista
        return option.label;
    };

    const transformFilteredList = (options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // validar si existe otra opción igual.
        const isExisting = motives.options.some((option) => {
            return inputValue.toLocaleLowerCase() === option.label.toLocaleLowerCase()
        });
        if (inputValue !== '' && !isExisting) {
            filtered.push({
                inputValue,
                label: `Agregar "${inputValue}"`,
            });
        }
        return filtered;
    }

    const autocompleteRenderOptions = (props, option) => {
        const { key, ...optionProps } = props;
        return (
            <li key={key} {...optionProps}>
                {option.label}
            </li>
        );
    }
    const handleAutocompleteChange = (e, newValue) => {
        if (typeof newValue === 'string') {
            setForm({ ...form, motive: newValue ?? '' })
        } else if (newValue && newValue.inputValue) {
            setForm({ ...form, motive: newValue?.inputValue ?? '' })
        } else {
            setForm({ ...form, motive: newValue?.label ?? '' })
        }
    }

    const handleDescriptionChange = (e) => setForm({ ...form, description: e.target.value });

    const handleGetMotives = async () => await fetchGetMotives(modalType);

    useEffect(() => {
        handleGetMotives();
    }, [modalType])

    return (
        <Dialog
            key={panelist.token + '-' + modalType}
            open={seeModal.subscribe || seeModal.unsubscribe}
            onClose={() => handleCloseDialog()}
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    handleChangePanelistStatus();
                },
            }}
            closeAfterTransition={false}
        >
            <DialogTitle>{selectModalText().title}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: '30px' }}>
                    {"Completa la información para " + selectModalText().contentText}
                </DialogContentText>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <InputLabel className="fs-6">Motivo</InputLabel>
                    {motiveIsLoading
                        ? <Skeleton height={30} />
                        : (
                            <>
                                <Autocomplete
                                    freeSolo
                                    options={motives.options ?? []}
                                    renderInput={(params) => <TextField {...params} placeholder="Selecciona o agrega un motivo" />}
                                    onChange={(e, newValue) => handleAutocompleteChange(e, newValue)}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    filterOptions={(options, params) => transformFilteredList(options, params)}
                                    getOptionLabel={(option) => transformOptionLabel(option)}
                                    renderOption={(props, option) => autocompleteRenderOptions(props, option)}
                                />
                                {motiveIsError.status
                                    ? motiveIsError.message.motive.map((x, i) => <span key={i} className="fs-6 text-danger p-0 m-0">{x}</span>)
                                    : ''}
                            </>
                        )}
                    <>
                        <InputLabel className="fs-6">Descripción</InputLabel>
                        <TextField
                            multiline
                            minRows={3}
                            maxRows={Infinity}
                            fullWidth
                            placeholder={selectModalText().textAreaPlaceholder}
                            onChange={(e) => handleDescriptionChange(e)}
                        />
                        {motiveIsError.status
                            ? motiveIsError.message.description.map((x, i) => <span key={i} className="fs-6 text-danger p-0 m-0">{x}</span>)
                            : ''}
                    </>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseDialog()}>Cancelar</Button>
                <Button
                    type="submit"
                    sx={{
                        bgcolor: "#46166c",
                        '&:hover': {
                            backgroundColor: '#46166c',
                            opacity: 0.9
                        }
                    }}
                    variant="contained"
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
}