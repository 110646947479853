import React from "react";
import ReactDOM from 'react-dom/client';
import { App } from "../../../App";
import { AvailableExportsTable } from "./table/AvailableExportsTable";

export const AvailableExports = () => {
    return (
        <>
            <div className="row" >
                <div className="col-12 mt-4">
                    <div className="card mb-4">
                        <div className="card-header">
                            <div className="content-head-card">
                                <label>Archivos disponibles</label>
                            </div>
                        </div>
                        <div className="card-body">
                            <AvailableExportsTable />
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

if (document.getElementById("jsx-panelists-exports")) {
    const el = document.getElementById("jsx-panelists-exports");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <AvailableExports />
        </App>
    );
}