import { Icon, TextField } from "@mui/material";
import {
    PANELIST_ACTIVITY_VALUES,
    PANELIST_GENDER_VALUES,
    PANELIST_RRSS_VALUES,
    PANELIST_SINO_VALUES,
    PANELIST_STATUS_VALUES,
    PANELIST_TYPE_VALUES
} from "../../../../helper/panelists";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInkIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';

const RrssListItem = ({ href, icon }) => {
    return (
        <a href={href} target="_blank" style={{ cursor: 'pointer' }}>
            {icon}
        </a>
    )
}

const RrssIcon = ({ rrss }) => {
    const icons = {
        instagram: <InstagramIcon fontSize="18px" />,
        tiktok: <i className="fa-brands fa-tiktok" style={{ fontSize: '18px' }}></i>,
        facebook: <FacebookIcon fontSize="18px" />,
        x: <XIcon fontSize="18px" />,
        linkedin: <LinkedInkIcon fontSize="18px" />
    };
    return <Icon style={{ height: 'auto', margin: '3px' }} key={rrss + Math.random()}>{icons[rrss]}</Icon>;
}

export const PANELIST_COLUMNS = () => [
    {
        id: "status",
        accessorKey: "status",
        size: 50,
        header: "Estado",
        filterVariant: "multi-select",
        filterSelectOptions: Object.entries(PANELIST_STATUS_VALUES).map(
            ([clave, valor]) => ({ text: valor, value: clave })
        ),
        Cell: ({ cell }) => {
            return PANELIST_STATUS_VALUES[cell.getValue()];
        }
    },
    {
        id: "type",
        accessorKey: "type",
        size: 50,
        header: "Tipo",
        filterVariant: "multi-select",
        filterSelectOptions: Object.entries(PANELIST_TYPE_VALUES).map(
            ([clave, valor]) => ({ text: valor, value: clave })
        ),
        Cell: ({ cell }) => {
            return PANELIST_TYPE_VALUES[cell.getValue()];
        }
    },
    {
        id: "name",
        accessorKey: "name",
        size: 100,
        header: "Nombre",
        filterVariant: "text",
    },
    {
        id: "email",
        accessorKey: "email",
        header: "Email",
        filterVariant: "text",
    },
    {
        id: "token",
        accessorKey: "token",
        header: "Token",
        filterVariant: "text",
    },
    {
        id: "dni",
        accessorKey: "dni",
        size: 50,
        header: "DNI / RUT",
        filterVariant: "text",
    },
    {
        id: "phone",
        accessorKey: "phone",
        size: 50,
        header: "Teléfono",
        filterVariant: "text",
    },
    {
        accessorKey: "gender",
        header: "Género",
        size: 20,
        filterVariant: "multi-select",
        filterSelectOptions: Object.entries(PANELIST_GENDER_VALUES).map(
            ([clave, valor]) => ({ text: valor, value: clave })
        ),
        Cell: ({ cell }) => {
            return PANELIST_GENDER_VALUES[cell.getValue()];
        }
    },
    {
        accessorFn: (originalRow) => parseInt(originalRow.age),
        accessorKey: "age",
        header: 'Edad',
        filterVariant: 'range-slider',
        muiFilterSliderProps: {
            min: 0,
            max: 100
        },
        size: 200,
    },
    {
        accessorFn: (originalRow) => new Date(parseISO(originalRow.birthdate)),
        id: 'birthdate',
        accessorKey: 'birthdate',
        header: 'Fecha de Cumpleaños',
        filterVariant: 'date',
        size: 100,
        Cell: ({ cell }) => format(cell.getValue(), "dd 'de' MMMM", { locale: es }),
        muiFilterDatePickerProps: {
            renderInput: (params) => <TextField {...params} size="small" />,
            inputFormat: "dd 'de' MMMM",
            disableMaskedInput: true,
            views: ['month', 'day'],
        },
    },
    {
        accessorFn: (originalRow) => originalRow.registration_at
            ? new Date(originalRow.registration_at)
            : null,
        id: 'registration_at',
        header: 'Fecha de Registro',
        filterVariant: 'datetime-range',
        size: 400,
        Cell: ({ cell }) => {
            const value = cell.getValue();
            if (!value) return '—';
            return format(value, 'dd-MM-yyyy HH:mm');
        },
        muiFilterDateTimePickerProps: {
            renderInput: (params) => <TextField {...params} size="small" />,
            inputFormat: "dd-MM-yyyy HH:mm",
        },
    },
    {
        accessorKey: "doubleOptin",
        header: "¿Email verificado?",
        size: 20,
        filterVariant: "multi-select",
        filterSelectOptions: Object.entries(PANELIST_SINO_VALUES).map(
            ([clave, valor]) => ({ text: valor, value: clave })
        ),
        Cell: ({ cell }) => {
            let value = (cell.getValue() === '' || Number(cell.getValue()) === 0) ? 0 : 1;
            return PANELIST_SINO_VALUES[value];
        }
    },
    {
        accessorKey: "isProfiled",
        header: "¿Está perfilado?",
        size: 20,
        filterVariant: "multi-select",
        filterSelectOptions: Object.entries(PANELIST_SINO_VALUES).map(
            ([clave, valor]) => ({ text: valor, value: clave })
        ),
        Cell: ({ cell }) => {
            let value = (cell.getValue() === '' || Number(cell.getValue()) === 0) ? 0 : 1;
            return PANELIST_SINO_VALUES[value];
        }
    },
    {
        accessorFn: (originalRow) => originalRow.last_interaction
            ? new Date(originalRow.last_interaction)
            : null,
        id: 'last_interaction',
        header: 'Última interacción',
        filterVariant: 'datetime-range',
        size: 400,
        Cell: ({ cell }) => {
            const value = cell.getValue();
            if (!value) return '—';
            return format(value, 'dd-MM-yyyy HH:mm');
        },
        muiFilterDateTimePickerProps: {
            renderInput: (params) => <TextField {...params} size="small" />,
            inputFormat: "dd-MM-yyyy HH:mm",
        },
    },
    {
        id: "activity",
        accessorKey: "activity",
        size: 50,
        header: "Actividad",
        filterVariant: "multi-select",
        filterSelectOptions: Object.entries(PANELIST_ACTIVITY_VALUES).map(
            ([clave, valor]) => ({ text: valor, value: clave })
        ),
        Cell: ({ cell }) => {
            return PANELIST_ACTIVITY_VALUES[cell.getValue()];
        }
    },
    {
        accessorKey: "rrss",
        header: "Redes Sociales",
        size: 200,
        filterVariant: "multi-select",
        filterSelectOptions: Object.entries(PANELIST_RRSS_VALUES).map(
            ([clave, valor]) => ({ text: valor, value: clave })
        ),
        Cell: ({ cell }) => {
            const rrss = Object.fromEntries(
                Object.entries(JSON.parse(cell.getValue()) || {})
                    .filter(([key, value]) => value != null)
            );
            const rrssKeys = Object.keys(rrss);
            return (
                <>
                    {
                        rrssKeys.length > 0 ?
                            (
                                rrssKeys.map(key => {
                                    if (rrss[key]) {
                                        return (
                                            <RrssListItem
                                                key={key}
                                                href={rrss[key]}
                                                icon={<RrssIcon rrss={key} />}
                                            />
                                        );
                                    }
                                    return null;
                                })
                            )
                            : (
                                "Sin redes sociales"
                            )
                    }
                </>
            )
        }
    },
    {
        accessorKey: "motivation",
        header: "Razón embajador",
        size: 300,
        filterVariant: "text"
    },
    {
        accessorFn: (originalRow) => originalRow.candidate_at
            ? new Date(originalRow.candidate_at)
            : null,
        id: 'candidate_at',
        header: 'Fecha postulación a embajador',
        filterVariant: 'datetime-range',
        size: 400,
        Cell: ({ cell }) => {
            const value = cell.getValue();
            if (!value) return '—';
            return format(value, 'dd-MM-yyyy HH:mm');
        },
        muiFilterDateTimePickerProps: {
            renderInput: (params) => <TextField {...params} size="small" />,
            inputFormat: "dd-MM-yyyy HH:mm",
        },
    },
    {
        accessorFn: (originalRow) => originalRow.accepted_at
            ? new Date(originalRow.accepted_at)
            : null,
        id: 'accepted_at',
        header: 'Fecha aceptación embajador',
        filterVariant: 'datetime-range',
        size: 400,
        Cell: ({ cell }) => {
            const value = cell.getValue();
            if (!value) return '—';
            return format(value, 'dd-MM-yyyy HH:mm');
        },
        muiFilterDateTimePickerProps: {
            renderInput: (params) => <TextField {...params} size="small" />,
            inputFormat: "dd-MM-yyyy HH:mm",
        },
    },
    {
        accessorFn: (originalRow) => originalRow.ambassador_end_at
            ? new Date(originalRow.ambassador_end_at)
            : null,
        id: 'ambassador_end_at',
        header: 'Fecha de término embajador',
        filterVariant: 'datetime-range',
        size: 400,
        Cell: ({ cell }) => {
            const value = cell.getValue();
            if (!value) return '—';
            return format(value, 'dd-MM-yyyy HH:mm');
        },
        muiFilterDateTimePickerProps: {
            renderInput: (params) => <TextField {...params} size="small" />,
            inputFormat: "dd-MM-yyyy HH:mm",
        },
    }

];