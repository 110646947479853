import React, { useState } from "react";
import ReactDOM from 'react-dom/client';
import { App } from "../../../App";
import { PanelistsTable } from "./table/PanelistsTable";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { EditPanelist } from "./modal/EditPanelist";
import { es } from "date-fns/locale";
import { usePanelistsStore } from "../../../store/PanelistsInsight/usePanelistsStore";
import { AddPoints } from "./modal/AddPoints";
import { AmbassadorResume } from "./modal/AmbassadorResume";
import { SubscribeStatusPanelistModal } from "./modal/SubscribeStatusPanelistModal";

export const Panelists = () => {
    const [seeModal, setSeeModal] = useState({
        edit: false,
        addPoints: false,
        ambassadorResume: false,
        subscribe: false,
        unsubscribe: false
    });
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [columnFilters, setColumnFilters] = useState([]);
    const {
        panelist,
        setPanelist,
    } = usePanelistsStore((state) => state);
    return (
        <>
            {seeModal.edit &&
                <EditPanelist
                    seeModal={seeModal}
                    setSeeModal={setSeeModal}
                    panelist={panelist}
                    pagination={pagination}
                    columnFilters={columnFilters}
                />
            }

            {seeModal.addPoints &&
                <AddPoints
                    seeModal={seeModal}
                    setSeeModal={setSeeModal}
                    panelist={panelist}
                />
            }

            {seeModal.ambassadorResume &&
                <AmbassadorResume
                    seeModal={seeModal}
                    setSeeModal={setSeeModal}
                    panelist={panelist}
                />
            }

            {(seeModal.subscribe || seeModal.unsubscribe) &&
                <SubscribeStatusPanelistModal
                    seeModal={seeModal}
                    setSeeModal={setSeeModal}
                    panelist={panelist}
                    pagination={pagination}
                    columnFilters={columnFilters}
                />
            }

            <div className="row" >
                <div className="col-12 mt-4">
                    <div className="card mb-4">
                        <div className="card-header">
                            <div className="content-head-card">
                                <label>Panelistas</label>
                            </div>
                        </div>
                        <div className="card-body">
                            <PanelistsTable
                                pagination={pagination}
                                setPagination={setPagination}
                                columnFilters={columnFilters}
                                setColumnFilters={setColumnFilters}
                                seeModal={seeModal}
                                setSeeModal={setSeeModal}
                                setPanelist={setPanelist}
                            />
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

if (document.getElementById("jsx-panelists-admin")) {
    const el = document.getElementById("jsx-panelists-admin");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es} >
                <Panelists {...props} />
            </LocalizationProvider>
        </App>
    );
}