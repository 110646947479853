import React, { useEffect, useMemo, useRef } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { usePanelistsStore } from "../../../../store/PanelistsInsight/usePanelistsStore";
import { PANELIST_COLUMNS } from "./PanelistsTableColumn";
import { PanelistsTableRowActionMenuItems } from "./PanelistsTableRowActionMenuItems";
import { MenuTablePanelists } from "./MenuTablePanelists";
import { Box } from "@mui/material";
import { format } from "date-fns";
import { ExportAlertStack } from "./ExportAlertStack";

export const PanelistsTable = ({
    seeModal,
    setSeeModal,
    setPanelist,
    columnFilters,
    setColumnFilters,
    pagination,
    setPagination }) => {

    const {
        panelists,
        total: rowCount,
        isLoadingPanelists,
        setIsLoadingPanelists,
        isError,
        fetchPanelists
    } = usePanelistsStore((state) => state);

    const debouncedRef = useRef(500);
    const previousValues = useRef({
        paginationPageIndex: pagination.pageIndex,
        paginationPageSize: pagination.pageSize,
        columnFilters
    });

    const columns = useMemo(PANELIST_COLUMNS, []);

    const table = useMaterialReactTable({
        columns,
        data: panelists,
        isClearable: true,
        enableHiding: true,
        initialState: {
            showColumnFilters: true, columnVisibility: {
                name: false,
                gender: false,
                last_interaction: false,
                registration_at: false,
                doubleOptin: false,
                isProfiled: false,
                birthdate: false,
                token: false,
                phone: false,
                candidate_at: false,
                accepted_at: false,
                ambassador_end_at: false,
                motivation: false,
                rrss: false,
                age: false
            }
        },
        manualFiltering: true,
        manualPagination: true,
        rowCount: rowCount,
        onColumnFiltersChange: setColumnFilters,
        onPaginationChange: setPagination,
        enableGlobalFilter: false,
        enableColumnActions: true,
        enableColumnFilters: true,
        enableBottomToolbar: true,
        enableFullScreenToggle: true,
        enableDensityToggle: false,
        localization: MRT_Localization_ES,
        positionToolbarAlertBanner: 'top',
        muiToolbarAlertBannerProps: {
            color: 'error',
            severity: 'error',
            children: isError.message
        },
        muiTablePaperProps: {
            sx: { boxShadow: "none !important", border: "none" },
        },
        muiFilterTextFieldProps: {
            placeholder: 'Buscar...',
        },
        state: {
            isLoading: isLoadingPanelists,
            pagination,
            columnFilters,
            showAlertBanner: isError.status,
        },
        positionActionsColumn: "first",
        displayColumnDefOptions: {
            "mrt-row-actions": { header: "Acciones" },
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <Box>
                <MenuTablePanelists
                    isLoadingPanelists={isLoadingPanelists}
                    setIsLoadingPanelists={setIsLoadingPanelists}
                    columnFilters={table.getState().columnFilters}
                    setColumnFilters={setColumnFilters}
                />
            </Box>
        ),
        enableRowActions: true,
        renderRowActionMenuItems: ({ closeMenu, row }) => [
            <PanelistsTableRowActionMenuItems
                key={Math.random()}
                closeMenu={closeMenu}
                seeModal={seeModal}
                setSeeModal={setSeeModal}
                setPanelist={setPanelist}
                row={row}
            />
        ]
    });

    const handleTransformColumnFilters = (filters) => {
        const dateFilterIds = [
            'registration_at',
            'last_interaction',
            'candidate_at',
            'accepted_at',
            'ambassador_end_at'
        ];
        let _columnFilters = filters.map(filter => {
            if (dateFilterIds.includes(filter.id)) {
                let [startDate, endDate] = filter.value.map(date => date ? new Date(date) : '');
                const formatedStartDate = startDate == '' ? null : format(startDate, 'dd-MM-yyyy HH:mm')
                const formatedEndDate = endDate == '' ? null : format(endDate, 'dd-MM-yyyy HH:mm')
                return { ...filter, value: [formatedStartDate, formatedEndDate] }
            }
            return filter
        });
        return _columnFilters
    }

    useEffect(() => {
        // previusValues es para identificar por separado si el estado cambia respecto a su valor anterior
        // asi puedo identificar si el useEffect se aplica por cambios en pagination o en columnFilters
        // y de esta manera, diferenciar el tiempo del setTimeOut a mas o menos tiempo segun corresponda
        // mejorando la experiencia de usuario al hacer click en "retrodecer" o "siguiente" pagina
        let time =
            previousValues.current.paginationPageIndex !== pagination.pageIndex
                || previousValues.current.paginationPageSize !== pagination.pageSize
                ? 100
                : 1000;
        if (debouncedRef != undefined) {
            clearTimeout(debouncedRef.current);
        }
        previousValues.current = {
            paginationPageIndex: pagination.pageIndex,
            paginationPageSize: pagination.pageSize,
            columnFilters
        };
        debouncedRef.current = setTimeout(() => {
            const _columnFilters = handleTransformColumnFilters(columnFilters);
            fetchPanelists(pagination, _columnFilters)
        }, time);
    }, [pagination.pageIndex, pagination.pageSize, columnFilters]);

    return (
        <>
            <ExportAlertStack />
            <MaterialReactTable table={table} />
        </>
    );
};