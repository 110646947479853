import { axiosApi } from "../axiosApi";

export const subscribe = async (panelist) => {
    try {
        const response = await axiosApi.put('/api/marketing/panelists/subscribe', panelist);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const unsubscribe = async (panelist) => {
    try {
        const response = await axiosApi.put('/api/marketing/panelists/unsubscribe', panelist);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getMotives = async (type) => {
    try {
        const response = await axiosApi.get(`/api/marketing/panelists/get-motives/${type}`);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};