import React, { useContext, useEffect, useMemo, useState } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { useExportPanelists } from "../../../../store/PanelistsInsight/usePanelistsStore";
import { ListItemIcon, MenuItem } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Swal from "sweetalert2";
import { format, formatDistance } from "date-fns";
import { AVAILABLE_PANELISTS_COLUMNS } from "./AvailableExportsTableColumns";

export const AvailableExportsTable = () => {

    const [mount, setMount] = useState(false);

    const {
        fetchDownloadExportedFile,
        fetchDeleteExportedFile,
        fetchGetAvailableExports,
        resetExportStatus,
        availableExports,
        availableExportsError: isError,
        availableExportsIsloading: isLoading
    } = useExportPanelists((state) => state);

    const handleGetAvailableExports = async () => await fetchGetAvailableExports();

    const handleDownloadExportedFile = async (hash, type, downloadFilename) => {
        fetchDownloadExportedFile(hash, type, downloadFilename)
            .then((status) => {
                //Si es 404 significa que el archivo ya no existe
                //por lo que no es necesario gatillar la funcion para eliminar
                if (status != 404) { fetchGetAvailableExports(); }
            });
        //Reiniciar alertas
        resetExportStatus(type);
    }

    const handleDeleteExportedFile = (type, hash) => {
        fetchDeleteExportedFile(hash, type).then((resp) => {
            if (resp?.status === 200) {
                Swal.fire({
                    title: "Archivo eliminado",
                    icon: "success"
                })
            } else {
                Swal.fire({
                    title: "Error al eliminar el archivo",
                    icon: "error"
                })
            }
            fetchGetAvailableExports();
            resetExportStatus(type);
        });
    }

    const columns = useMemo(AVAILABLE_PANELISTS_COLUMNS, []);

    const table = useMaterialReactTable({
        columns,
        data: availableExports ?? [],
        enableHiding: true,
        enableColumnActions: true,
        enableBottomToolbar: true,
        enableFullScreenToggle: true,
        enableDensityToggle: false,
        enableColumnFilters: false,
        enableGlobalFilter: false,
        localization: MRT_Localization_ES,
        positionToolbarAlertBanner: 'top',
        initialState: {
            showColumnFilters: false,
            columnVisibility: {
                hash: false,
            }
        },
        muiToolbarAlertBannerProps: {
            color: 'warning',
            severity: 'warning',
            children: isError?.message
        },
        muiTablePaperProps: {
            sx: { boxShadow: "none !important", border: "none" },
        },
        state: {
            isLoading: isLoading,
            showAlertBanner: isError?.status,
        },
        enableRowActions: true,
        positionActionsColumn: 'last',
        displayColumnDefOptions: {
            "mrt-row-actions": { header: "Acciones" },
        },
        renderRowActionMenuItems: ({ closeMenu, row }) => [
            <MenuItem
                key={'download-' + row.original.hash}
                onClick={() => {
                    closeMenu();
                    handleDownloadExportedFile(row.original.hash, row.original.type, row.original.downloadFilename)
                }}
            >
                <ListItemIcon sx={{ margin: 0, padding: 0 }}><FileDownloadIcon fontSize="18px" /></ListItemIcon>
                Descargar
            </MenuItem>,
            <MenuItem
                key={'delete-' + row.original.hash}
                onClick={() => {
                    closeMenu();
                    handleDeleteExportedFile(row.original.type, row.original.hash)
                }}
            >
                <ListItemIcon sx={{ margin: 0, padding: 0 }}><DeleteForeverIcon fontSize="18px" /></ListItemIcon>
                Eliminar
            </MenuItem>
        ]
    });

    useEffect(() => {
        if (!mount) {
            handleGetAvailableExports();
            setMount(true)
        }
    }, [mount]);

    return (
        <>
            <MaterialReactTable table={table} />
        </>
    );
};