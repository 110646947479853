import { create } from "zustand";
import { getMotives, subscribe, unsubscribe } from "../../api/sip/subscribe";

export const useSubscribeStore = create((set, get) => {
    return {
        isLoading: false,
        isError: {
            status: false,
            message: {}
        },
        motives: {
            options: [],
        },
        fetchSubscribe: async (panelist) => {
            set({
                isLoading: true,
                isError: {
                    status: false,
                    message: {}
                }
            })
            try {
                const { status, data } = await subscribe(panelist);
                return { status, data };
            } catch (error) {
                set({
                    isLoading: false,
                    isError: {
                        status: true,
                        message: error?.data?.errors ?? {}
                    }
                })
            }
        },
        fetchUnsubscribe: async (panelist) => {
            set({
                isLoading: true,
                isError: {
                    status: false,
                    message: {}
                }
            })
            try {
                const { status, data } = await unsubscribe(panelist);
                return { status, data };
            } catch (error) {
                set({
                    isLoading: false,
                    isError: {
                        status: true,
                        message: error?.data?.errors ?? {}
                    }
                })
            }
        },
        fetchGetMotives: async (type) => {
            set({
                isLoading: true,
                isError: {
                    status: false,
                    message: {}
                }
            })
            try {
                const { status, data } = await getMotives(type);
                if (status === 200) {
                    set({
                        motives: {
                            ...get().motives,
                            options: data.motives ?? []
                        },
                        isLoading: false,
                        isError: {
                            status: false,
                            message: {}
                        },
                    })
                }
            } catch (error) {
                console.log("error al traer motivos", error)
                set({
                    isLoading: false,
                    isError: {
                        status: true,
                        message: error?.data?.errors ?? {}
                    }
                })
            }
        },
    }
});