import { useEffect, useRef, useState } from "react"
import { KpiCard } from "../ui/KpiCard"
import { useAmbassadorStore } from "../../../../store/Ambassador/useAmbassadorStore"
import { TextField } from "@mui/material"
import { TableChartCard } from "../ui/TableChartCard"
import { DateTimePicker } from "@mui/x-date-pickers"
import { endOfToday, format, startOfMonth } from "date-fns"

export const AmbassadorResumeModalBody = ({ panelist }) => {

    const debouncedRef = useRef(1000);

    const [dateFilter, setDateFilter] = useState({
        from: startOfMonth(new Date()),
        to: endOfToday()
    })

    const handleChangeDateTimePicker = (event, rangeType) => {
        setDateFilter({
            ...dateFilter,
            [rangeType]: event
        })
    }

    const {
        fetchAmbassadorValidRecruits,
        validRecruits,
        fetchAmbassadorRecruitsCompletedSurveys,
        completedSurveys,
        fetchAmbassadorDeliveredRewards,
        deliveredRewards,
        isLoading,
        isError
    } = useAmbassadorStore(state => state)

    const handleFetchValidRecruitsChart = async () => {
        const _filter = {
            token: panelist?.token,
            dateRange: { from: format(dateFilter.from, 'dd-MM-yyyy HH:mm'), to: format(dateFilter.to, 'dd-MM-yyyy HH:mm') },
        }
        await fetchAmbassadorValidRecruits(_filter);
    }

    const handleFetchCompletedSurveysChart = async () => {
        const _filter = {
            token: panelist?.token,
            dateRange: { from: format(dateFilter.from, 'dd-MM-yyyy HH:mm'), to: format(dateFilter.to, 'dd-MM-yyyy HH:mm') },
        }
        await fetchAmbassadorRecruitsCompletedSurveys(_filter);
    }

    const handleFetchDeliveredRewardsChart = async () => {
        const _filter = {
            token: panelist?.token,
            dateRange: { from: format(dateFilter.from, 'dd-MM-yyyy HH:mm'), to: format(dateFilter.to, 'dd-MM-yyyy HH:mm') },
        }
        await fetchAmbassadorDeliveredRewards(_filter);
    }

    useEffect(() => {
        if (debouncedRef != undefined) {
            clearTimeout(debouncedRef.current);
        }
        debouncedRef.current = setTimeout(() => {
            handleFetchValidRecruitsChart();
            handleFetchCompletedSurveysChart();
            handleFetchDeliveredRewardsChart();
        }, 1000);
    }, [dateFilter])

    return (
        <>
            <div className="d-flex justify-content-center my-4 gap-4">
                <div key={'container-dpicker-from'}>
                    <DateTimePicker
                        key={'datePicker-from'}
                        label="Desde"
                        onChange={(e) => handleChangeDateTimePicker(e, 'from')}
                        value={dateFilter.from}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                size="small"
                                error={isError.deliveredRewards.status}
                                helperText={
                                    isError.deliveredRewards.status
                                        ? isError.deliveredRewards.message['dateRange.from']
                                        : ''
                                }
                            />
                        }
                        inputFormat="dd/MM/yyyy HH:mm"
                    />
                </div>
                <div key={'container-dpicker-to'}>
                    <DateTimePicker
                        key={'datePicker-to'}
                        label="Hasta"
                        onChange={(e) => handleChangeDateTimePicker(e, 'to')}
                        value={dateFilter.to}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                size="small"
                                error={isError.deliveredRewards.status}
                                helperText={
                                    isError.deliveredRewards.status
                                        ? isError.deliveredRewards.message['dateRange.to']
                                        : ''
                                }
                            />
                        }
                        inputFormat="dd/MM/yyyy HH:mm"
                    />

                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <TableChartCard
                        isLoading={isLoading.deliveredRewards}
                        chartData={deliveredRewards.chart ?? []}
                        totalPoints={deliveredRewards.totalPoints}
                        dateFilter={dateFilter}
                        rangeTotal={deliveredRewards.rangeTotal}
                    />
                </div>
            </div>
            <div className="row my-2">
                <div className="col-12 col-md-6">
                    <KpiCard
                        kpiKey={'completedSurveys'}
                        isLoading={isLoading.completedSurveys}
                        isError={isError.completedSurveys}
                        title="Encuestas completas"
                        chartData={completedSurveys.chart ?? []}
                        filterTotal={completedSurveys.rangeTotal ?? 0}
                        todayTotal={completedSurveys.todayTotal ?? 0}
                        total={completedSurveys.total ?? 0}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <KpiCard
                        kpiKey={'validRecruits'}
                        isLoading={isLoading.validRecruits}
                        isError={isError.validRecruits}
                        title="Reclutados perfilados"
                        chartData={validRecruits.chart ?? []}
                        filterTotal={validRecruits.rangeTotal ?? 0}
                        todayTotal={validRecruits.todayTotal ?? 0}
                        total={validRecruits.total ?? 0}
                    />
                </div>
            </div>
        </>
    )
}