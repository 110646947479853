import { formatDistance } from "date-fns";
import { es } from 'date-fns/locale';

export const AVAILABLE_PANELISTS_COLUMNS = () => [
    {
        accessorKey: "hash",
        size: 100,
        header: "ID",
    },
    {
        accessorKey: "downloadFilename",
        size: 100,
        header: "Nombre de archivo",
    },
    {
        accessorKey: "timestamp",
        size: 50,
        header: "Creado hace",
        Cell: ({ cell }) => {
            return formatDistance(
                new Date(cell.getValue()),
                new Date(),
                { locale: es }
            );
        }
    },
    {
        accessorKey: "expireAt",
        size: 50,
        header: "Expira en",
        Cell: ({ cell }) => {
            return formatDistance(
                new Date(cell.getValue()),
                new Date(), // Fecha actual
                { locale: es }
            );
        }
    },
];